// store/index.js

import { createStore } from 'vuex';
import onesimpleapi from '@/store/modules/onesimpleapi';

import {
  createRecords,
  fetchRecords,
  fetchRelatedRecords,

  createRecord,
  fetchRecord,
  updateRecord,
  deleteRecord,

} from '@/services/airtable';

const opportunitiesTableName = process.env.VUE_APP_OPPORTUNITIES_TABLE_NAME;
const companiesTableName = process.env.VUE_APP_COMPANIES_TABLE_NAME;
const contactsTableName = process.env.VUE_APP_CONTACTS_TABLE_NAME;
const jobsTableName = process.env.VUE_APP_JOBS_TABLE_NAME;

function getMonthOptions() {
  const currentYear = new Date().getFullYear();
  const options = [
    { label: `${currentYear - 3}`, value: `${currentYear - 3}` },
    { label: `${currentYear - 2}`, value: `${currentYear - 2}` },
    { label: `${currentYear - 1}`, value: `${currentYear - 1}` },
    { label: `${currentYear}`, value: `${currentYear}` },
  ];

  // Dynamically add the last six months
  const currentDate = new Date();
  for (let i = 5; i >= 0; i--) {
    const d = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
    const monthName = d.toLocaleString('en-US', { month: 'long' });
    const year = d.getFullYear();
    options.push({ label: `${monthName} (${year})`, value: `${monthName.substring(0, 3)} ${year}` });
  }

  return options;
}

export default createStore({
  modules: {
    onesimpleapi,
  },

  state: {
    // Search
    searchResultsOpps: [],
    searchResultsCompanies: [],
    searchResultsContacts: [],
    searchResultsJobs: [],
    loadingSearchResultsOpps: false,
    loadingSearchResultsCompanies: false,
    loadingSearchResultsContacts: false,
    loadingSearchResultsJobs: false,

    // Vendors Services
    vendorsServices: [],
    fetchingVendorsServices: false,

    // Vendors
    vendors: [],
    fetchingVendors: false,

    // Vendor
    vendor: [],
    creatingVendor: false,
    fetchingVendor: false,
    updatingVendor: false,
    deletingVendor: false,

    // Vendor Page, Services tab
    vendorServices: [],
    fetchingVendorServices: false,

    // Vendor Page, Oppportunities tab
    vendorOpps: [],
    fetchingVendorOpps: false,

    // Vendor Page, Jobs tab
    vendorJobs: [],
    fetchingVendorJobs: false,

    // Vendor Service Detail
    vendorService: [],
    creatingVendorService: false,
    fetchingVendorService: false,
    updatingVendorService: false,
    deletingVendorService: false,

    // Services
    services: [],
    fetchingServices: false,

    // Service
    service: [],
    fetchingService: false,
    creatingService: false,
    updatingService: false,
    deletingService: false,

    // Units
    units: [],
    fetchingUnits: false,

    // Unit
    unit: [],
    fetchingUnit: false,
    creatingUnit: false,
    updatingUnit: false,
    deletingUnit: false,

    // Team
    team: [],
    fetchingTeam: false,

    // Companies
    companies: [],
    fetchingCompanies: false,

    // Company
    company: [],
    creatingCompany: false,
    fetchingCompany: false,
    updatingCompany: false,
    deletingCompany: false,

    // Company Page, Contacts Tab
    companyContacts: [],
    fetchingCompanyContacts: false,

    // Company Page, Opportunities Tab
    companyOpps: [],
    fetchingCompanyOpps: false,

    // Company Page, Jobs Tab
    companyJobs: [],
    fetchingCompanyJobs: false,

    // Contacts
    contacts: [],
    fetchingContacts: false,

    // Contact
    contact: [],
    creatingContact: false,
    fetchingContact: false,
    updatingContact: false,
    deletingContact: false,

    // Contact Page, Companies Tab
    contactCompanies: [],
    fetchingContactCompanies: false,

    // Contact Page, Opportunities Tab
    contactOpps: [],
    fetchingContactOpps: false,

    // Contact Page, Jobs Tab
    contactJobs: [],
    fetchingContactJobs: false,

    // Opps
    opps: [],
    fetchingOpps: false,

    // Opp
    opp: [],
    creatingOpp: false,
    fetchingOpp: false,
    updatingOpp: false,
    deletingOpp: false,

    // Opp page, Jobs Tab
    oppJobs: [],
    fetchingOppJobs: false,

    // Orders
    orders: [],
    fetchingOrders: false,

    // Quotes
    quotes: [],
    fetchingQuotes: false,

    // Countries
    countries: [],
    fetchingCountries: false,

    // Languages
    languages: [],
    fetchingLanguages: false,

    // Language Pairs
    languagePairs: [],
    fetchingLanguagePairs: false,

    // Specialities
    specialities: [],
    fetchingSpecialities: false,

    // Industries
    industries: [],
    fetchingIndustries: false,

    // Jobs
    jobs: [],
    fetchingJobs: false,
    creatingJobs: false,

    // Job
    job: [],
    creatingJob: false,
    fetchingJob: false,
    updatingJob: false,
    deletingJob: false,

    // PMs
    PMs: [],
    fetchingPMs: false,

    // Currencies
    currencies: [
      { code: 'EUR', symbol: '€' },
      { code: 'USD', symbol: '$' },
      { code: 'GBP', symbol: '£' },
      { code: 'Other', symbol: '' },
    ],

    userGroups: {
      'user_2VPB8YdeGEYPmc0sUSzcTkyFHgC': { name: 'Rodrigo Galindez', photo: 'https://languageburo.com/content/snippet-team/rodrigo.jpg', group: 'Admin' },
      'user_2VUz5aW3sMHVoTrNWl17ZLtyGng': { name: 'Valentina Farallo', photo: 'https://languageburo.com/content/snippet-team/valentina.jpg', group: 'Admin' },
      'user_2VUwUbjDclZDR0SXRe3FLJufimI': { name: 'Estéfani Laux', photo: 'https://languageburo.com/content/snippet-team/esteani.jpg', group: 'Admin' },
      'user_2VUxoKbIp8lIOp6xux8wj6ue0tC': { name: 'Selene Brito', photo: 'https://languageburo.com/content/snippet-team/selene-brito.jpg', group: 'Admin' },
      'user_2VUxW5OTxe8xpiGFhZKWKhCwpgj': { name: 'Sara Cimmino', photo: 'https://languageburo.com/content/snippet-team/sara.jpg', group: 'Admin' },

      'user_2VUtNjePjSV9vvXVyrDK06Dsg0J': { name: 'Camila Régis', photo: 'https://languageburo.com/content/snippet-team/camila-regis.jpg', group: 'SDR' },
      'user_2VUwkzSKPJYKV38TXoAgAucHjfk': { name: 'Giuliana Carvalheira', photo: 'https://languageburo.com/content/snippet-team/giuliana-carvalheira.png', group: 'SDR' },
      'user_2Y7ukVzpzSi4rd1LtlsAX2P5JO4': { name: 'Nadine Schwantes', photo: 'https://languageburo.com/content/snippet-team/nadine.jpg', group: 'SDR' },
      'user_2Y7wkQGzTbOftNg7ycWeW4opGuF': { name: 'Fernando Bisi', photo: 'https://languageburo.com/content/snippet-team/fernando.jpg', group: 'SDR' },
      'user_2VUxHtXrhpliBjSGFEtXIguxeJf': { name: 'Leandro Klauck', photo: 'https://languageburo.com/content/snippet-team/leandro.jpg', group: 'SDR' },
      'user_2i3Fy3b2QxYdfVmZ4L0Lm5Qz9i7': { name: 'Victória Rodrigues', photo: 'https://languageburo.com/content/snippet-team/victoria.jpg', group: 'SDR' },

      'user_2aPH32mhwc0QNtSFgBh0AllMiwn': { name: 'Ana Souza', photo: 'https://languageburo.com/content/snippet-team/ana.jpg', group: 'PM' },
      'user_2b7iKhEyNhfxwL8tIF3CyfV2m4H': { name: 'Luciana Tablado', photo: 'https://languageburo.com/content/snippet-team/luciana.jpg', group: 'PM' },

      'user_2aogINzPrqwkiQWEI9iU7kmJYBk': { name: 'Leandro Klauck', photo: 'https://languageburo.com/content/snippet-team/leandro.jpg', group: 'VM' },

      'user_2b4mpFUmTjk3voJJ5tOCDofEKIh': { name: 'Alexandra Crasnaru', photo: 'https://languageburo.com/content/snippet-team/alexandra.jpg', group: 'Finance' },
    },

    // Statuses
    statuses: [
      'Quote: Open',
      'Quote: Won',
      'Quote: Abandoned',
      'Quote: Lost',
      'Order: In Progress',
      'Order: Not Started Yet',
      'Order: Completed',
      'Order: Cancelled',
    ],

    // Contact Stages
    contactStages: [
      'Potential Customer',
      'Current Customer',
      'DNC',
    ],

    months: getMonthOptions(),
  },

  mutations: {
    // Search
    setSearchResultsOpps(state, searchResults) {
      state.searchResultsOpps = searchResults;
    },
    resetSearchResultsOpps(state) {
      state.searchResultsOpps = [];
    },
    setSearchResultsCompanies(state, searchResults) {
      state.searchResultsCompanies = searchResults;
    },
    resetSearchResultsCompanies(state) {
      state.searchResultsCompanies = [];
    },
    setSearchResultsContacts(state, searchResults) {
      state.searchResultsContacts = searchResults;
    },
    resetSearchResultsContacts(state) {
      state.searchResultsContacts = [];
    },
    setSearchResultsJobs(state, searchResults) {
      state.searchResultsJobs = searchResults;
    },
    resetSearchResultsJobs(state) {
      state.searchResultsJobs = [];
    },

    // Searching flags
    setLoadingSearchResultsOpps(state, isLoading) {
      state.loadingSearchResultsOpps = isLoading;
    },
    setLoadingSearchResultsCompanies(state, isLoading) {
      state.loadingSearchResultsCompanies = isLoading;
    },
    setLoadingSearchResultsContacts(state, isLoading) {
      state.loadingSearchResultsContacts = isLoading;
    },
    setLoadingSearchResultsJobs(state, isLoading) {
      state.loadingSearchResultsJobs = isLoading;
    },

    toggleCreatingState(state, { property, value }) {
      state[property] = value;
      // console.log(property, value); // debug
    },

    toggleFetchingState(state, { property, value }) {
      state[property] = value;
      // console.log(property, value); // debug
    },

    toggleUpdatingState(state, { property, value }) {
      state[property] = value;
      // console.log(property, value); // debug
    },

    toggleDeletingState(state, { property, value }) {
      state[property] = value;
      // console.log(property, value); // debug
    },

    setStateVariable(state, { stateVariable, data }) {
      state[stateVariable] = data;
    },

    // Aux
    sortArrayAlphabetically(state, { arrayName, field }) {
      state[arrayName] = state[arrayName].slice().sort((a, b) => a.fields[field].localeCompare(b.fields[field]));
    },
  },

  actions: {
    // Search: Opps
    async searchOpps({ commit }, { filterString }) {
      commit('setLoadingSearchResultsOpps', true);

      try {
        const opps = await fetchRecords(opportunitiesTableName, filterString);
        commit('setSearchResultsOpps', opps);
        return { opps };

      } catch (error) {
        console.error('Error fetching opportunities:', error);
        throw error;

      } finally {
        commit('setLoadingSearchResultsOpps', false);
      }
    },
    resetSearchResultsOpps({ commit }) {
      commit('resetSearchResultsOpps');
    },

    // Search: Companies
    async searchCompanies({ commit }, { filterString }) {
      commit('setLoadingSearchResultsCompanies', true);

      try {
        const companies = await fetchRecords(companiesTableName, filterString);
        commit('setSearchResultsCompanies', companies);
        return { companies };

      } catch (error) {
        console.error('Error fetching companies:', error);
        throw error;

      } finally {
        commit('setLoadingSearchResultsCompanies', false);
      }
    },
    resetSearchResultsCompanies({ commit }) {
      commit('resetSearchResultsCompanies');
    },

    // Search: Contacts
    async searchContacts({ commit }, { filterString }) {
      commit('setLoadingSearchResultsContacts', true);

      try {
        const contacts = await fetchRecords(contactsTableName, filterString);
        commit('setSearchResultsContacts', contacts);
        return { contacts };

      } catch (error) {
        console.error('Error fetching contacts:', error);
        throw error;

      } finally {
        commit('setLoadingSearchResultsContacts', false);
      }
    },
    resetSearchResultsContacts({ commit }) {
      commit('resetSearchResultsContacts');
    },

    // Search: Jobs
    async searchJobs({ commit }, { filterString }) {
      commit('setLoadingSearchResultsJobs', true);

      try {
        const jobs = await fetchRecords(jobsTableName, filterString);
        commit('setSearchResultsJobs', jobs);
        return { jobs };

      } catch (error) {
        console.error('Error fetching jobs:', error);
        throw error;

      } finally {
        commit('setLoadingSearchResultsJobs', false);
      }
    },
    resetSearchResultsJobs({ commit }) {
      commit('resetSearchResultsJobs');
    },

    // DONE Error handling
    async createRecords({ commit }, { tableName, newRecords, creatingStateVariable }) {
      commit('toggleCreatingState', { property: creatingStateVariable, value: true });

      try {
        const createdRecords = await createRecords(tableName, newRecords);
        // do we need to add a commit to set the property here?
        return createdRecords;

      } catch (error) {
        console.error(error);
        throw error;

      } finally {
        commit('toggleCreatingState', { property: creatingStateVariable, value: false });
      }
    },

    // DONE Error handling
    async fetchRecords({ commit }, { tableName, filter = null, stateVariable, fetchingStateVariable }) {
      commit('toggleFetchingState', { property: fetchingStateVariable, value: true });

      try {
        const records = await fetchRecords(tableName, filter);
        commit('setStateVariable', { stateVariable, data: records });
        return records;

      } catch (error) {
        console.error(error);
        throw error;

      } finally {
        commit('toggleFetchingState', { property: fetchingStateVariable, value: false });

      }
    },

    // DONE Error handling
    async fetchRelatedRecords({ commit }, { parentTableName, childTableName, recordId, relatedFieldName, stateVariable, fetchingStateVariable }) {
      commit('toggleFetchingState', { property: fetchingStateVariable, value: true });

      try {
        const records = await fetchRelatedRecords(parentTableName, childTableName, recordId, relatedFieldName);

        if (stateVariable) {
          commit('setStateVariable', { stateVariable, data: records });
        }

        return records;

      } catch (error) {
        console.error(error);
        throw error;

      } finally {
        commit('toggleFetchingState', { property: fetchingStateVariable, value: false });
      }
    },

    // DONE Error handling
    async createRecord({ commit }, { tableName, newRecord, creatingStateVariable }) {
      commit('toggleCreatingState', { property: creatingStateVariable, value: true });

      try {
        const createdRecord = await createRecord(tableName, newRecord);
        // do we need to add a commit to set the property here?
        return createdRecord;

      } catch (error) {
        console.error(error);
        throw error;

      } finally {
        commit('toggleCreatingState', { property: creatingStateVariable, value: false });

      }
    },

    // DONE Error handling
    async fetchRecord({ commit }, { tableName, recordId, stateVariable, fetchingStateVariable }) {
      commit('toggleFetchingState', { property: fetchingStateVariable, value: true });

      try {
        const record = await fetchRecord(tableName, recordId);
        commit('setStateVariable', { stateVariable, data: record });
        return record;

      } catch (error) {
        console.error(error);
        throw error;

      } finally {
        commit('toggleFetchingState', { property: fetchingStateVariable, value: false });

      }
    },

    // DONE Error handling
    async updateRecord({ commit }, { tableName, recordId, updatedFields, updatingStateVariable }) {
      commit('toggleUpdatingState', { property: updatingStateVariable, value: true });

      try {
        const updatedRecord = await updateRecord(tableName, recordId, updatedFields);

        if (!updatedRecord) {
          // The update was not successful, and the response is falsy (e.g., null or false)
          const errorMsg = `Error updating ${tableName}: The response from updateRecord is null`;
          console.error(errorMsg);
          throw new Error(errorMsg);
        }

        // The update was successful, and the response is truthy (e.g., an object)
        return true;

      } catch (error) {
        // An error was thrown during the update process (e.g., network error, API problem, etc.)
        console.error(`Error updating ${tableName} in store:`, error);
        throw error;

      } finally {
        commit('toggleUpdatingState', { property: updatingStateVariable, value: false });

      }
    },

    // DONE Error handling
    async deleteRecord({ commit }, { tableName, recordId, deletingStateVariable }) {
      commit('toggleDeletingState', { property: deletingStateVariable, value: true });

      try {
        await deleteRecord(tableName, recordId);

      } catch (error) {
        console.error(error);
        throw error;

      } finally {
        commit('toggleDeletingState', { property: deletingStateVariable, value: false });

      }
    },

    async sortArrayAlphabetically({ commit }, { arrayName, field }) {
      commit('sortArrayAlphabetically', { arrayName, field });
    },
  },

  getters: {
    // Search
    allSearchedOpps: (state) => state.searchResultsOpps,
    allSearchedCompanies: (state) => state.searchResultsCompanies,
    allSearchedContacts: (state) => state.searchResultsContacts,
    allSearchedJobs: (state) => state.searchResultsJobs,

    isLoadingSearchResultsOpps: (state) => state.loadingSearchResultsOpps,
    isLoadingSearchResultsCompanies: (state) => state.loadingSearchResultsCompanies,
    isLoadingSearchResultsContacts: (state) => state.loadingSearchResultsContacts,
    isLoadingSearchResultsJobs: (state) => state.loadingSearchResultsJobs,

    isCreating: (state) => (property) => {
      if (state[property] === undefined) {
        console.warn(`Property "${property}" does not exist in state.`);
      }
      return state[property];
    },

    isFetching: (state) => (property) => {
      if (state[property] === undefined) {
        console.warn(`Property "${property}" does not exist in state.`);
      }
      return state[property];
    },

    isUpdating: (state) => (property) => {
      if (state[property] === undefined) {
        console.warn(`Property "${property}" does not exist in state.`);
      }
      return state[property];
    },

    isDeleting: (state) => (property) => {
      if (state[property] === undefined) {
        console.warn(`Property "${property}" does not exist in state.`);
      }
      return state[property];
    },

    getProperty: (state) => (property) => {
      if (state[property] === undefined) {
        console.warn(`Property "${property}" does not exist in state.`);
      }
      return state[property];
    },

    // Months
    allMonths: (state) => state.months,

    getCurrencySymbol: (state) => {
      const symbols = {};
      state.currencies.forEach(currency => {
        symbols[currency.code] = currency.symbol;
      });
      return symbols;
    },

    // Returns formatted arrays
    allCurrenciesOptions: (state) => {
      return state.currencies.map(currency => ({
        label: currency.code,
        value: currency.code,
      }));
    },

    allStatusesOptions: (state) => {
      return state.statuses.map(status => ({
        label: status,
        value: status,
      }));
    },

    allContactStagesOptions: (state) => {
      return state.contactStages.map(status => ({
        label: status,
        value: status,
      }));
    },

    allTeamOptions: (state) => {
      return state.team.map(teamMember => ({
        label: teamMember.fields['Name'],
        value: teamMember.id,
      }));
    },

    allPMsOptions: (state) => {
      return state.PMs.map(teamMember => ({
        label: teamMember.fields['Name'],
        value: teamMember.id,
      }));
    },

    allCompaniesOptions(state) {
      return state.companies.map(company => ({
          label: company.fields['Name'],
          value: company.id,
          currency: company.fields['Preferred Currency'],
          pm: company.fields['Preferred PM Lookup'],
          contacts: company.fields['Contacts Lookup']
      }));
    },

    allContactsOptions(state) {
      return state.contacts.map(contact => ({
          label: contact.fields['Name'],
          value: contact.id,
          email: contact.fields['Email'],
          companies: contact.fields['Companies Lookup']
      }));
    },

    allLanguagePairsOptions(state) {
      return state.languagePairs.map(languagePair => ({
          label: languagePair.fields['Name'],
          value: languagePair.id,
          price: languagePair.fields['Translation PPW in EUR']
      }));
    },

    allWorkableVendorsOptions(state) {
      return state.workableVendors.map(workableVendor => ({
          label: workableVendor.fields['Full Name'],
          value: workableVendor.id
      }));
    },

    allVendorsOptions(state) {
      return state.vendors.map(vendor => ({
          label: vendor.fields['Full Name'],
          value: vendor.id,
          blacklisted: vendor.fields['Blacklisted?']
      }));
    },

    allLanguagesOptions(state) {
      return state.languages.map(language => ({
          label: language.fields['Name'],
          value: language.id,
      }));
    },

    allCountriesOptions: (state) => {
      return state.countries.map(country => ({
        label: country.fields['Name'],
        value: country.id,
      }));
    },

    allServicesOptions: (state) => {
      return state.services.map(service => ({
        label: service.fields['Name'],
        value: service.id,
      }));
    },

    allUnitsOptions: (state) => {
      return state.units.map(unit => ({
        label: unit.fields['Name'],
        value: unit.id,
      }));
    },

    allSpecialitiesOptions: (state) => {
      return state.specialities.map(speciality => ({
        label: speciality.fields['Name'],
        value: speciality.id,
      }));
    },

    allIndustriesOptions: (state) => {
      return state.industries.map(industry => ({
        label: industry.fields['Name'],
        value: industry.id,
      }));
    },
  },
});