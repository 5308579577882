<!-- Orders.vue -->

<template>
  <div v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])">
    <OrdersHeader
      :selectedMonth="selectedMonth"
      :thisYearOrders="thisYearOrders"
      :lastYearOrders="lastYearOrders"
      @showAlert="showAlert"
      @showError="showError"
      @totalRevenueChanged="handleTotalRevenueChanged"
      @selectMonth="mixinSelectMonth"
    />

    <!-- Navigation -->
    <div v-if="!isFetching('fetchingOrders')">
      <div class="row mb-3">
        <div class="col">
          <ul class="nav nav-pills" id="tabNav" v-show="tabsVisible">
            <li class="nav-item small">
              <a class="nav-link" :class="{ 'active': activeTab === 'orders' }" @click.prevent="setActiveTab('orders')" data-bs-toggle="tab" href="#orders">Orders</a>
            </li>

            <li class="nav-item small" v-if="mixinUserInRoles(['Admin'])">
              <a class="nav-link" :class="{ 'active': activeTab === 'reports' }" @click.prevent="setActiveTab('reports')" data-bs-toggle="tab" href="#reports">Reports</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Tabs -->
    <div class="tab-content">
      <!-- Orders -->
      <div id="orders" class="tab-pane" :class="{ 'show active': activeTab === 'orders' }">
        <div v-if="!isFetching('fetchingOrders')">
          <div class="shadow-sm bg-white p-3 rounded">
            <!-- Table filters and search -->
            <div class="filters mb-1 p-2 ps-0 pe-0">
              <div class="d-flex align-items-center">
                <!-- Filters -->
                <div class="d-flex align-items-center">
                  <!-- Filter: Status -->
                  <div class="dropdown me-2">
                    <button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" id="statusDropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Status: <strong>{{ selectedStatus }}</strong>
                    </button>
                    <div class="dropdown-menu shadow border-0" aria-labelledby="statusDropdownMenuButton">
                      <h6 class="dropdown-header">Filter by Status</h6>
                      <a
                        class="dropdown-item d-flex justify-content-between align-items-center small"
                        :class="{ active: selectedStatus === status.status }"
                        href="#"
                        v-for="status in statusData"
                        :key="status.status"
                        @click.prevent="selectStatus(status.status)"
                      >
                        <span>{{ status.status }} ({{ status.count }})</span>
                        <span v-if="mixinUserInRoles(['Admin'])" class="text-muted small ms-2">€{{ mixinFormatNumber(status.revenue) }}</span>
                      </a>
                    </div>
                  </div>

                  <!-- Filter: Created By -->
                  <div class="dropdown me-2">
                    <button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" id="createdByDropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Created By: <strong>{{ selectedCreatedBy }}</strong>
                    </button>
                    <div class="dropdown-menu shadow border-0" aria-labelledby="createdByDropdownMenuButton">
                      <h6 class="dropdown-header">Filter by Created By</h6>
                      <a
                        class="dropdown-item d-flex justify-content-between align-items-center small"
                        :class="{ active: selectedCreatedBy === creator.creator }"
                        href="#"
                        v-for="creator in createdByData"
                        :key="creator.creator"
                        @click.prevent="selectCreatedBy(creator.creator)"
                      >
                        <span>{{ creator.creator }} ({{ creator.count }})</span>
                        <span v-if="mixinUserInRoles(['Admin'])" class="text-muted small ms-2">€{{ mixinFormatNumber(creator.revenue) }}</span>
                      </a>
                    </div>
                  </div>

                  <!-- Filter: PM -->
                  <div class="dropdown me-2">
                    <button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" id="pmDropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      PM: <strong>{{ selectedPM }}</strong>
                    </button>
                    <div class="dropdown-menu shadow border-0" aria-labelledby="pmDropdownMenuButton">
                      <h6 class="dropdown-header">Filter by PM</h6>
                      <a
                        class="dropdown-item d-flex justify-content-between align-items-center small"
                        :class="{ active: selectedPM === pm.pm }"
                        href="#"
                        v-for="pm in pmData"
                        :key="pm.pm"
                        @click.prevent="selectPM(pm.pm)"
                      >
                        <span>{{ pm.pm }} ({{ pm.count }})</span>
                        <span v-if="mixinUserInRoles(['Admin'])" class="text-muted small ms-2">€{{ mixinFormatNumber(pm.revenue) }}</span>
                      </a>
                    </div>
                  </div>

                  <!-- Filter: Company -->
                  <div class="dropdown me-2" v-if="mixinUserInRoles(['Admin'])">
                    <button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" id="companyDropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Company: <strong>{{ selectedCompany }}</strong>
                    </button>
                    <div class="dropdown-menu shadow border-0 dropdown-menu-scroll" aria-labelledby="companyDropdownMenuButton">
                      <h6 class="dropdown-header">Filter by Company</h6>
                      <a
                        class="dropdown-item d-flex justify-content-between align-items-center small"
                        :class="{ active: selectedCompany === company.company }"
                        href="#"
                        v-for="company in companyData"
                        :key="company.company"
                        @click.prevent="selectCompany(company.company)"
                      >
                        <span class="text-truncate" style="max-width: 300px;">{{ company.company }} ({{ company.count }})</span>
                        <span v-if="mixinUserInRoles(['Admin'])" class="text-muted small ms-2">€{{ mixinFormatNumber(company.revenue) }}</span>
                      </a>
                    </div>
                  </div>

                  <div v-if="mixinUserInRoles(['Admin'])">
                    <div v-if="totalSelectedRevenue" class="text-muted small">
                      Revenue of selected rows: €{{ mixinFormatNumber(totalSelectedRevenue) }}
                    </div>
                  </div>
                </div>

                <!-- Showing text, Pagination and Search -->
                <div class="d-flex align-items-center ms-auto">
                  <!-- Showing text -->
                  <div class="text-muted small me-3">{{ showingText }}</div>

                  <!-- Pagination -->
                  <VTPagination
                    v-model:currentPage="currentPage"
                    :totalPages="totalPages"
                    :maxPageLinks="3"
                    :boundaryLinks="false"
                    class="me-2"
                  >
                    <template #next>
                      <span class="prevent-select">Next</span>
                    </template>

                    <template #previous>
                      <span class="prevent-select">Previous</span>
                    </template>
                  </VTPagination>

                  <!-- Search -->
                  <input v-model="filters.name.value" type="text" class="form-control form-control-sm" placeholder="Filter by Quote, Order, Company, Contact, Email, Created By or PM" style="width: 450px;" />
                </div>
              </div>
            </div>

            <!-- Table -->
            <div class="table-responsive">
              <VTable :data="tableRows" :pageSize="pageSize" v-model:currentPage="currentPage" @totalPagesChanged="totalPages = $event" @stateChanged="handleStateChanged" :filters="filters" sortHeaderClass="sort-header" class="table custom-table border">
                <template #head>
                  <tr class="bg-light custom-row small">
                    <!-- Checkbox -->
                    <th class="sticky-column text-end small" style="min-width: 50px;">
                      <!-- <input type="checkbox" class="form-check-input" v-model="selectAll" @change="toggleSelectAll" /> -->
                    </th>

                    <!-- Index -->
                    <th class="sticky-column small" style="left: 50px; min-width: 50px;"></th>

                    <!-- Actions -->
                    <th class="text-nowrap sticky-column small" style="left: 100px; min-width: 100px;">
                      Actions
                    </th>

                    <!-- Status -->
                    <VTh sortKey="status" class="text-nowrap small" style="min-width: 120px;">
                      Status
                    </VTh>

                    <!-- Quote Number -->
                    <th class="text-nowrap text-end small" style="min-width: 100px;">
                      Opp
                    </th>

                    <!-- Order -->
                    <VTh defaultSort="desc" sortKey="orderId" class="text-nowrap text-end small" style="min-width: 80px;">
                      Order
                    </VTh>

                    <!-- Client -->
                    <VTh sortKey="company" class="text-nowrap sticky-column small" style="left: 200px; min-width: 320px;">
                      Client
                    </VTh>

                    <!-- PM -->
                    <VTh sortKey="pm" class="text-nowrap small" style="">
                      PM
                    </VTh>

                    <!-- Order Start Date -->
                    <VTh :customSort="dateSort('orderStartDate')" class="text-nowrap text-end small" style="">
                      Started On
                    </VTh>

                    <!-- Order Delivery Date -->
                    <VTh :customSort="dateSort('orderDeliveryDate')" class="text-nowrap text-end small" style="">
                      Delivery By
                    </VTh>

                    <!-- Revenue -->
                    <VTh v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])" sortKey="value" class="text-nowrap text-end small" style="min-width: 100px;">
                      Revenue
                    </VTh>

                    <!-- Profit -->
                    <VTh v-if="mixinUserInRoles(['Admin'])" sortKey="profit" class="text-nowrap text-end small" style="min-width: 100px;">
                      Profit
                    </VTh>

                    <!-- Margin -->
                    <VTh v-if="mixinUserInRoles(['Admin', 'PM'])" sortKey="margin" class="text-nowrap text-end small" style="min-width: 80px;">
                      Margin
                    </VTh>

                    <!-- Currency -->
                    <VTh sortKey="currency" class="text-nowrap text-end small" style="">
                      Currency
                    </VTh>

                    <!-- Created On -->
                    <VTh :customSort="dateSort('createdOn')" class="text-nowrap text-end small" style="">
                      Created On
                    </VTh>

                    <!-- Created By -->
                    <VTh sortKey="createdBy" class="text-nowrap small" style="">
                      Created By
                    </VTh>

                    <!-- Forwarded By -->
                    <VTh sortKey="forwardedBy" class="text-nowrap small" style="">
                      Forwarded By
                    </VTh>

                    <!-- Vendors -->
                    <VTh v-if="mixinUserInRoles(['Admin', 'PM'])" sortKey="vendors" class="text-nowrap small" style="min-width: 320px;">
                      Vendors
                    </VTh>

                    <!-- Language Pairs -->
                    <VTh sortKey="languagePairs" class="text-nowrap small" style="min-width: 320px;">
                      Language Pairs
                    </VTh>

                    <!-- Title -->
                    <VTh sortKey="title" class="text-nowrap small" style="min-width: 320px;">
                      Title
                    </VTh>

                    <!-- Private Notes -->
                    <VTh sortKey="privateNotes" class="text-nowrap small" style="min-width: 320px;">
                      Private Notes
                    </VTh>

                    <!-- Invoice -->
                    <VTh sortKey="invoice" class="text-nowrap text-end small" style="">
                      Invoice
                    </VTh>

                    <!-- Delivery Confirmed? -->
                    <!-- <VTh sortKey="deliveryConfirmed" class="text-nowrap" style="">
                      Delivery Confirmed?
                    </VTh> -->

                    <!-- Quality Issues Dropdown -->
                    <VTh sortKey="qualityIssuesDropdown" class="text-nowrap text-end small" style="">
                      Quality Issues Dropdown
                    </VTh>

                    <!-- Quality Issues? -->
                    <VTh sortKey="qualityIssues" class="text-nowrap small" style="">
                      Quality Issues?
                    </VTh>
                  </tr>
                </template>

                <template #body="{rows}">
                    <VTr
                      v-for="(row, index) in rows"
                      :key="index"
                      :row="row"
                      v-expandable
                      class="custom-row small"
                      :class="{ 'bg-light': selectedRows[row.id] }"
                      @click="toggleRowSelection(row.id, $event)"
                    >
                    <!-- Checkbox -->
                    <td class="text-nowrap align-middle text-end small sticky-column" style="min-width: 50px;" @click.stop="toggleRowSelection(row.id)">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :checked="selectedRows[row.id]"
                        @click.stop="toggleRowSelection(row.id)"
                      />
                    </td>

                    <!-- Index -->
                    <td class="text-nowrap align-middle text-end text-muted small sticky-column" style="left: 50px; min-width: 50px;" @click.stop>
                      {{ (currentPage - 1) * pageSize + index + 1 }}
                    </td>

                    <!-- Actions -->
                    <td class="text-nowrap align-middle sticky-column" style="left: 100px; min-width: 100px;" @click.stop>
                      <!-- Opens in new tab -->
                      <a :href="`/opps/${row.id}`" target="_blank" class="btn btn-sm btn-outline-secondary"><i class="bi bi-box-arrow-up-right small"></i> Open</a>

                      <!-- Opens in same tab -->
                      <!-- <router-link :to="`/opps/${row.id}`" class="btn btn-sm btn-outline-secondary">
                        <i class="bi bi-box-arrow-up-right small"></i> Open
                      </router-link> -->
                    </td>

                    <!-- Status -->
                    <td class="text-nowrap align-middle" style="" @click.stop>
                      <span :class="['badge custom-badge', mixinGetBadgeClass(row.status)]">
                        {{ row.status.replace('Order: ', '') }}
                      </span>
                    </td>

                    <!-- Quote Number -->
                    <td class="text-nowrap align-middle text-end" style="min-width: 100px';" @click.stop>
                      {{ row.quoteNumber }}
                    </td>

                    <!-- Order ID -->
                    <td class="text-nowrap align-middle text-end" style="min-width: 80px;" @click.stop>
                      {{ row.orderId }}
                    </td>

                    <!-- Client -->
                    <td class="text-nowrap align-middle sticky-column border-end" style="left: 200px;" @click.stop>
                      <div class="text-truncate" style="max-width: 300px;">
                        <a :href="`/companies/${row.companyId}`" target="_blank" class="text-dark text-decoration-none hover-blue">
                          {{ row.company }}
                        </a>
                        <br>
                        <span class="text-muted small">
                          <a :href="`/contacts/${row.contactId}`" target="_blank" class="text-muted text-decoration-none hover-blue">
                            {{ row.contact }}
                          </a>
                        </span>
                        <br>
                        <span class="text-muted small">
                          {{ row.contactEmail }}
                        </span>
                        <!-- <br>
                        <span class="text-muted small">
                          {{ row.contactTitle }}
                        </span> -->
                      </div>
                    </td>

                    <!-- PM -->
                    <td class="text-nowrap align-middle small" @click.stop>
                      <span :class="row.pm === 'Unassigned' ? '' : 'user border p-0 pe-2 d-inline-block'">
                        <img :src="row.pmPhoto ? row.pmPhoto : ''" class="rounded-circle user-picture-small">
                        {{ row.pm }}
                      </span>
                    </td>

                    <!-- Order Start Date -->
                    <td class="text-nowrap align-middle text-end" @click.stop>
                      {{ formatDate(row.orderStartDate) }}
                    </td>

                    <!-- Order Delivery Date -->
                    <td class="text-nowrap align-middle text-end" @click.stop>
                      {{ formatDate(row.orderDeliveryDate) }}
                    </td>

                    <!-- Revenue -->
                    <td v-if="mixinUserInRoles(['Admin', 'PM', 'SDR', 'Finance'])" class="text-nowrap align-middle text-end" style="min-width: 100px;" @click.stop>
                      {{ getCurrencySymbol[row.currency] }}{{ mixinFormatNumber(row.value) }}
                    </td>

                    <!-- Profit -->
                    <td v-if="mixinUserInRoles(['Admin'])" class="text-nowrap align-middle text-end" @click.stop>
                      €{{ mixinFormatNumber(row.profit) }}
                    </td>

                    <!-- Margin -->
                    <td v-if="mixinUserInRoles(['Admin', 'PM'])" class="text-nowrap align-middle text-end" :class="mixinGetPercentageClass(row.margin)" @click.stop>
                      {{ row.margin }}%
                    </td>

                    <!-- Currency -->
                    <td class="text-nowrap align-middle text-end" style="" @click.stop>
                      <span :class="['badge custom-badge', mixinGetCurrencyBadgeClass(row.currency)]" @click.stop>
                        {{ row.currency }}
                      </span>
                    </td>

                    <!-- Created On -->
                    <td class="text-nowrap align-middle text-end" @click.stop>
                      {{ formatDate(row.createdOn) }}
                    </td>

                    <!-- Created By -->
                    <td class="text-nowrap align-middle small" @click.stop>
                      <span class="user border p-0 pe-2 d-inline-block">
                        <img :src="row.createdByPhoto" class="rounded-circle user-picture-small"> {{ row.createdBy }}
                      </span>
                    </td>

                    <!-- Forwarded By -->
                    <td class="text-nowrap align-middle small" @click.stop>
                      <span :class="row.forwardedBy === 'Unassigned' ? '' : 'user border p-0 pe-2 d-inline-block'">
                        <img :src="row.forwardedByPhoto ? row.forwardedByPhoto : ''" class="rounded-circle user-picture-small">
                        {{ row.forwardedBy }}
                      </span>
                    </td>

                    <!-- Vendors -->
                    <td v-if="mixinUserInRoles(['Admin', 'PM'])"  class="text-nowrap align-middle">
                      <div class="text-truncate" style="max-width: 300px;">
                        <span v-for="(vendor, index) in row.vendors" :key="index">
                          <router-link :to="`/vendors/${vendor.id}`" target="_blank" class="text-dark text-decoration-none hover-blue">{{ vendor.name }}</router-link>
                          <span v-if="index < row.vendors.length - 1">, </span>
                        </span>
                      </div>
                    </td>

                    <!-- Language Pairs -->
                    <td class="text-wrap align-middle">
                      <div class="text-truncate" style="max-width: 300px;">
                        <div v-if="row.languagePairs">
                          <span v-for="(pair, index) in row.languagePairs" :key="index">
                            {{ pair.replace('>', '›') }}
                          </span>
                        </div>
                      </div>
                    </td>

                    <!-- Title -->
                    <td class="text-nowrap align-middle">
                      <div class="text-truncate" style="max-width: 300px;">
                        {{ row.title }}
                      </div>
                    </td>

                    <!-- Private Notes -->
                    <td class="text-nowrap align-middle">
                      <div class="text-truncate" style="max-width: 300px;">
                        {{ row.privateNotes }}
                      </div>
                    </td>

                    <!-- Invoice -->
                    <td class="text-nowrap align-middle text-end" @click.stop>
                      {{ row.invoice }}
                    </td>

                    <!-- Delivery Confirmed -->
                    <!-- <td class="text-nowrap align-middle" @click.stop>
                      {{ row.deliveryConfirmed }}
                    </td> -->

                    <!-- Quality Issues Dropdown -->
                    <td class="text-nowrap align-middle text-end" @click.stop>
                      {{ row.qualityIssuesDropdown }}
                    </td>

                    <!-- Quality Issues? -->
                    <td class="text-nowrap align-middle" @click.stop>
                      {{ row.qualityIssues }}
                    </td>
                  </VTr>

                  <tr class="tfoot" v-if="mixinUserInRoles(['Admin'])">
                    <td class="sticky-column" style="min-width: 50px;"></td>
                    <td class="sticky-column" style="left: 50px; min-width: 50px;"></td>
                    <td class="sticky-column" style="left: 100px; min-width: 100px;"></td>
                    <td colspan="3"></td>
                    <td class="sticky-column border-end" style="left: 200px; min-width: 320px;"></td>
                    <td colspan="3"></td>
                    <td class="text-nowrap align-middle text-end text-muted small" style="min-width: 100px;">€{{ mixinFormatNumber(totalFilteredRevenue) }}</td>
                    <td class="text-nowrap align-middle text-end text-muted small">€{{ mixinFormatNumber(totalFilteredProfit) }}</td>
                    <td class="text-nowrap align-middle text-end text-muted small">{{ mixinFormatMargin(averageFilteredMargin) }}</td>
                  </tr>
                </template>
              </VTable>

              <div v-if="totalFilteredItems === 0" class="text-center text-muted p-3">
                <p class="mb-0">No results found based on the current filters.</p>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="text-center text-muted p-5">
          <div class="spinner-border text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>

      <!-- Reports -->
      <div id="reports" class="tab-pane" :class="{ 'show active': activeTab === 'reports' }">
        <div v-if="!isFetching('fetchingOrders')">
          <div class="p-0 shadow-sm bg-white rounded mb-3">
            <!-- Section header  -->
            <div class="p-4 pb-2">
              <div class="row align-items-center">
                <div class="col">
                  <span class="fs-5 fw-semibold mb-3">Reports</span>
                </div>
              </div>
            </div>

            <div class="p-4 pt-2">

              <ReportOppsByTeamMember :records="thisYearOrders" />

              <ReportOppsByCompany :records="thisYearOrders" />

            </div>
          </div>
        </div>

        <div v-else class="text-center text-muted p-5">
          <div class="spinner-border text-secondary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    <p class="">You are not authorized to see this page.</p>
  </div>
</template>

<script>
/* global bootstrap */
import { mapGetters } from 'vuex';
import OrdersHeader from '@/components/OrdersHeader.vue';
import ReportOppsByTeamMember from '@/components/ReportOppsByTeamMember.vue';
import ReportOppsByCompany from '@/components/ReportOppsByCompany.vue';

export default {
  name: 'AppOrders',

  emits: [
    'showAlert',
    'showError',
  ],

  components: {
    OrdersHeader,
    ReportOppsByTeamMember,
    ReportOppsByCompany,
  },

  data() {
    return {
      filters: {
        name: { value: '', keys: ['quoteNumber', 'orderId', 'company', 'contact', 'contactEmail', 'createdBy', 'pm'], custom: this.nameFilter },
        status: { value: '', keys: ['status'] }, // Used in the dropdown only
        createdBy: { value: '', keys: ['createdBy'] }, // Used in the dropdown only
        pm: { value: '', keys: ['pm'] }, // Used in the dropdown only
        company: { value: '', keys: ['company'] }, // Used in the dropdown only
      },

      selectAll: false,
      selectedRows: {},

      clickedRow: null,

      totalPages: 1, // TODO: This gives a warning, but not sure how to fix. It doesn't alter the functionality though
      currentPage: 1,
      pageSize: 50,

      totalFilteredItems: 0,

      selectedStatus: 'All',
      selectedCreatedBy: 'All',
      selectedPM: 'All',
      selectedCompany: 'All',

      selectedMonth: {
        label: '',
        value: '',
      },

      thisYearOrders: [],
      lastYearOrders: [],

      expandText: false,
      expandedRows: {},

      totalFilteredRevenue: 0,
      totalFilteredProfit: 0,

      totalRevenue: 0,

      tabsVisible: false,
      activeTab: 'orders',

      tableName: 'Opportunities',
      stateVariable: 'orders',
      fetchingStateVariable: 'fetchingOrders',
    };
  },

  computed: {
    ...mapGetters([
      'isCreating',
      'isFetching',
      'isUpdating',
      'isDeleting',

      'getProperty',
      'getCurrencySymbol',
      'allMonths',
    ]),

    totalSelectedRevenue() {
      return this.tableRows.reduce((total, row) => {
        if (this.selectedRows[row.id]) {
          total += row.value;
        }
        return total;
      }, 0);
    },

    averageFilteredMargin() {
      if (this.totalFilteredRevenue === 0) {
        return 0;
      }
      return (this.totalFilteredProfit / this.totalFilteredRevenue);
    },

    uniqueStatuses() {
      const statuses = this.thisYearOrders.map(order => order.fields['Status']);
      const orderStatuses = statuses.filter(status => !status.startsWith('Quote:'));
      const uniqueStatuses = [...new Set(orderStatuses)];
      uniqueStatuses.sort();
      return ['All', ...uniqueStatuses];
    },

    statusData() {
      const statusOrder = ['In Progress', 'Not Started Yet', 'Completed', 'Cancelled'];
      const data = this.uniqueStatuses.map(status => {
        let ordersWithStatus;
        if (status === 'All') {
          ordersWithStatus = this.thisYearOrders;
        } else {
          ordersWithStatus = this.thisYearOrders.filter(order => order.fields['Status'] === status);
        }
        return {
          status: status.replace('Order: ', ''),
          count: ordersWithStatus.length,
          revenue: ordersWithStatus.reduce((sum, order) => sum + order.fields['9 - Gross Revenue in €'], 0),
        };
      });

      data.sort((a, b) => statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status));

      return data;
    },

    uniqueCreatedBy() {
      const createdByList = this.thisYearOrders.map(order => order.fields['Created By Lookup'][0]);
      const uniqueCreatedBy = [...new Set(createdByList)];
      uniqueCreatedBy.sort();
      return ['All', ...uniqueCreatedBy];
    },

    createdByData() {
      const data = this.uniqueCreatedBy.map(creator => {
        let ordersByCreator;
        if (creator === 'All') {
          ordersByCreator = this.thisYearOrders;
        } else {
          ordersByCreator = this.thisYearOrders.filter(order => order.fields['Created By Lookup'][0] === creator);
        }
        return {
          creator,
          count: ordersByCreator.length,
          revenue: ordersByCreator.reduce((sum, order) => sum + order.fields['9 - Gross Revenue in €'], 0),
        };
      });

      data.sort((a, b) => b.revenue - a.revenue);

      return data;
    },

    uniqueCompanies() {
      const companyList = this.thisYearOrders.map(order => order.fields['Company Lookup'][0]);
      const uniqueCompanies = [...new Set(companyList)];
      uniqueCompanies.sort();
      return ['All', ...uniqueCompanies];
    },

    companyData() {
      const data = this.uniqueCompanies.map(company => {
        let ordersByCompany;
        if (company === 'All') {
          ordersByCompany = this.thisYearOrders;
        } else {
          ordersByCompany = this.thisYearOrders.filter(order => {
            if (order.fields['Company Lookup']) {
              return order.fields['Company Lookup'][0] === company;
            }
            return false;
          });
        }

        // Count the number of unique companies
        const uniqueCompanies = new Set(ordersByCompany.map(order => order.fields['Company Lookup'][0]));

        return {
          company,
          count: company === 'All' ? uniqueCompanies.size : 1,
          revenue: ordersByCompany.reduce((sum, order) => sum + order.fields['9 - Gross Revenue in €'], 0),
        };
      });

      data.sort((a, b) => b.revenue - a.revenue);

      return data;
    },

    uniquePMs() {
      const pmList = this.thisYearOrders.map(order => order.fields['PM Lookup'][0]);
      const uniquePMs = [...new Set(pmList)];
      uniquePMs.sort();
      return ['All', ...uniquePMs];
    },

    pmData() {
      const data = this.uniquePMs.map(pm => {
        let ordersByPM;
        if (pm === 'All') {
          ordersByPM = this.thisYearOrders;
        } else {
          ordersByPM = this.thisYearOrders.filter(order => order.fields['PM Lookup'][0] === pm);
        }
        return {
          pm,
          count: ordersByPM.length,
          revenue: ordersByPM.reduce((sum, order) => sum + order.fields['9 - Gross Revenue in €'], 0),
        };
      });

      data.sort((a, b) => b.revenue - a.revenue);

      return data;
    },

    showingText() {
      const start = (this.currentPage - 1) * this.pageSize + 1;
      const end = Math.min(this.currentPage * this.pageSize, this.totalFilteredItems);
      return `Showing ${start}-${end} of ${this.totalFilteredItems}`;
    },

    tableRows() {
      return this.thisYearOrders.map(row => {
        let vendors = row.fields['Vendors'] ? row.fields['Vendors'].split(',').map(pair => pair.trim()) : [];
        let vendorIds = row.fields['Vendors ID'] ? row.fields['Vendors ID'] : [];

        let vendorLinks = vendors.map((vendor, index) => {
          return {
            name: vendor,
            id: vendorIds[index]
          };
        });

        return {
          id: row.id,
          status: row.fields['Status'],
          quoteNumber: row.fields['Quote No.'],
          orderId: row.fields['OID'],
          // value: row.fields['9 - Gross Revenue in €'],
          value: row.fields['4 - Total in Opp Currency'],
          currency: row.fields['Currency'],
          company: row.fields['Company Lookup'] ? row.fields['Company Lookup'].join(', ') : '',
          companyId: row.fields['Company ID'] ? row.fields['Company ID'].join(', ') : '',
          contact: row.fields['Contact Lookup'] ? row.fields['Contact Lookup'].join(', ') : '',
          contactId: row.fields['Contact ID'] ? row.fields['Contact ID'].join(', ') : '',
          contactEmail: row.fields['Email Lookup'] ? row.fields['Email Lookup'].join(', ') : '',
          contactTitle: row.fields['Contact Title'] ? row.fields['Contact Title'].join(', ') : '',
          createdOn: this.convertToDate(row.fields['Created On']),
          createdBy: row.fields['Created By Lookup'] ? row.fields['Created By Lookup'].join(', ') : '',
          createdByPhoto: row.fields['Created By Photo'] ? row.fields['Created By Photo'][0].url : '',
          forwardedBy: row.fields['Forwarded By Lookup'] ? row.fields['Forwarded By Lookup'].join(', ') : '',
          forwardedByPhoto: row.fields['Forwarded By Photo'] ? row.fields['Forwarded By Photo'][0].url : '',
          pm: row.fields['PM Lookup'] ? row.fields['PM Lookup'].join(', ') : '',
          pmPhoto: row.fields['PM Photo'] ? row.fields['PM Photo'][0].url : '',
          orderStartDate: this.convertToDate(row.fields['Order Start Date']),
          orderDeliveryDate: this.convertToDate(row.fields['Order Delivery Date']),
          vendors: vendorLinks,
          languagePairs: row.fields['Language Pairs'] ? row.fields['Language Pairs'].split(',').map(pair => pair.trim()).join(', '): '',
          title: row.fields['Title'],
          privateNotes: row.fields['Private Notes'] ? row.fields['Private Notes'].trim() : '', // Trimming the notes here
          publicNotes: row.fields['Public Notes'] ? row.fields['Public Notes'].trim() : '', // Trimming the notes here
          invoice: row.fields['Invoice'],
          // deliveryConfirmed: row.fields['Delivery Confirmed?'],
          qualityIssuesDropdown: row.fields['Quality Issues Dropdown'],
          qualityIssues: row.fields['Quality Issues?'],
          profit: row.fields['11 - Profit in €'],
          margin: typeof row.fields['12 - Margin'] === 'number' ? row.fields['12 - Margin'].toFixed(2) : '0',
        };
      });
    },
  },

  watch: {
    'filters.name.value': function() {
      this.totalFilteredRevenue = 0;
      this.totalFilteredProfit = 0;
    },

    // totalRevenue(newVal) {
    //   document.title = `Orders: €${this.mixinFormatNumber(newVal)}`;
    // },
  },

  methods: {
    showAlert(type, message) {
      this.$emit('showAlert', type, message);
    },

    showError(errorMessage) {
      this.$emit('showError', errorMessage);
    },

    expandRow(rowId) {
      this.expandedRows[rowId] = !this.expandedRows[rowId];
    },

    toggleRowSelection(rowId) {
      // this.selectedRows[rowId] = !this.selectedRows[rowId];

      if (event.target.type === 'checkbox') {
        this.selectedRows[rowId] = !this.selectedRows[rowId];
      }
    },

    setActiveTab(tabName) {
      this.activeTab = tabName;
      // This next line is crucial: it manually triggers Bootstrap's tab show action
      const tabElement = document.querySelector(`#tabNav .nav-link[href="#${tabName}"]`);
      if (tabElement) {
        new bootstrap.Tab(tabElement).show();
      }
    },

    handleTotalRevenueChanged(newTotalRevenue) {
      this.totalRevenue = newTotalRevenue;
    },

    nameFilter(filterValue, row) {
      return this.filters.name.keys.some(key => String(row[key]).toLowerCase().includes(filterValue.toLowerCase()));
    },

    selectStatus(status) {
      this.selectedStatus = status;
      const statusFilterValue = status === 'All' ? '' : status;
      this.filters.status.value = statusFilterValue;
      this.currentPage = 1;
    },

    selectCreatedBy(createdBy) {
      this.selectedCreatedBy = createdBy;
      const createdByFilterValue = createdBy === 'All' ? '' : createdBy;
      this.filters.createdBy.value = createdByFilterValue;
      this.currentPage = 1;
    },

    selectCompany(company) {
      this.selectedCompany = company;
      const companyFilterValue = company === 'All' ? '' : company;
      this.filters.company.value = companyFilterValue;
      this.currentPage = 1;
    },

    selectPM(pm) {
      this.selectedPM = pm;
      const pmFilterValue = pm === 'All' ? '' : pm;
      this.filters.pm.value = pmFilterValue;
      this.currentPage = 1;
    },

    convertToDate(dateString) {
      if (typeof dateString === 'string' && dateString !== '') {
        return dateString;
      }
      return null;
    },

    dateSort(sortKey) {
      return (a, b, sortOrder) => {
        let date1 = new Date(a[sortKey]).getTime();
        let date2 = new Date(b[sortKey]).getTime();

        if (sortOrder === 1) {
          return date1 - date2
        } else if (sortOrder === -1) {
          return date2 - date1
        } else {
          return 0
        }
      }
    },

    handleStateChanged(tableState) {
      this.totalFilteredItems = tableState.rowsPrePagination.length;

      this.totalFilteredRevenue = 0;
      this.totalFilteredProfit = 0;

      for (let row of tableState.rowsPrePagination) {
        this.totalFilteredRevenue += row.value || 0;
        this.totalFilteredProfit += row.profit || 0;
      }
    },

    formatDate(dateString) {
      if (typeof dateString === 'string' && dateString !== '') {
        let date = new Date(dateString);
        return date.getDate() + ' ' + date.toLocaleString('en-US', { month: 'short' }) + ' ' + date.getFullYear();
      }
      return '';
    },

    toggleSelectAll() {
      this.tableRows.forEach(row => {
        this.selectedRows[row.id] = this.selectAll;
      });
    },

    getFilterString(currentMonth, currentYear) {
      const monthIndex = new Date(Date.parse(currentMonth +" 1, 2012")).getMonth() + 1;
      const firstDayOfMonth = `01-${currentMonth}-${currentYear}`;
      const lastDayOfMonth = new Date(currentYear, monthIndex, 0).getDate() + `-${currentMonth}-${currentYear}`;

      return `AND(
        OR(
          {Status} = "Order: In Progress",
          {Status} = "Order: Not Started Yet",
          {Status} = "Order: Completed",
          {Status} = "Order: Cancelled"
        ),
        OR(
          IS_AFTER({Order Start Date}, DATETIME_PARSE('${firstDayOfMonth}', 'DD-MMM-YYYY')),
          {Order Start Date} = DATETIME_PARSE('${firstDayOfMonth}', 'DD-MMM-YYYY')
        ),
        OR(
          IS_BEFORE({Order Start Date}, DATETIME_PARSE('${lastDayOfMonth}', 'DD-MMM-YYYY')),
          {Order Start Date} = DATETIME_PARSE('${lastDayOfMonth}', 'DD-MMM-YYYY')
        )
      )`;
    },

    getFilterStringForPeriod(startDate, endDate) {
      const start = `${startDate.getDate()}-${startDate.getMonth()+1}-${startDate.getFullYear()}`;
      const end = `${endDate.getDate()}-${endDate.getMonth()+1}-${endDate.getFullYear()}`;

      return `AND(
        OR(
          {Status} = "Order: In Progress",
          {Status} = "Order: Not Started Yet",
          {Status} = "Order: Completed",
          {Status} = "Order: Cancelled"
        ),
        OR(
          IS_AFTER({Order Start Date}, DATETIME_PARSE('${start}', 'DD-MM-YYYY')),
          {Order Start Date} = DATETIME_PARSE('${start}', 'DD-MM-YYYY')
        ),
        OR(
          IS_BEFORE({Order Start Date}, DATETIME_PARSE('${end}', 'DD-MM-YYYY')),
          {Order Start Date} = DATETIME_PARSE('${end}', 'DD-MM-YYYY')
        )
      )`;
    },

    async fetchRecordsForPeriod(startDate, endDate) {
      const filterString = this.getFilterStringForPeriod(startDate, endDate);

      try {
        const records = await this.$store.dispatch('fetchRecords', {
          tableName: this.tableName,
          filter: filterString,
          stateVariable: this.stateVariable,
          fetchingStateVariable: this.fetchingStateVariable,
        });

        return records;

      } catch (error) {
        console.error(`Error fetching records for period ${startDate} - ${endDate}:`, error);
      }
    },

    async fetchRecords(month, year) {
      const filterString = this.getFilterString(month, year);

      try {
        const records = await this.$store.dispatch('fetchRecords', {
          tableName: this.tableName,
          filter: filterString,
          stateVariable: this.stateVariable,
          fetchingStateVariable: this.fetchingStateVariable,
        });

        return records;

      } catch (error) {
        console.error(`Error fetching records for ${month} - ${year}:`, error);
      }
    },
  },

  async mounted() {
    this.$nextTick(() => {
      this.setActiveTab(this.activeTab);
    });
  },

  async created() {
    this.mixinCheckUserAuthentication();

    document.title = `Orders`;

    if (!this.selectedMonth.value) {
      const currentDate = new Date();
      currentDate.setDate(1);
      currentDate.setHours(12, 0, 0);
      const currentMonth = currentDate.toLocaleString('en-US', { month: 'short' });
      const currentYear = currentDate.getFullYear();
      const monthName = currentDate.toLocaleString('en-US', { month: 'long' });
      this.selectedMonth = {
        label: `${monthName} (${currentYear})`,
        value: `${currentMonth} ${currentYear}`,
      };
    }

    if (this.selectedMonth.value === 'This Year' || this.selectedMonth.value === 'Last Year') {
      await this.mixinSelectMonth(this.selectedMonth.value);
    } else {
      const [month, year] = this.selectedMonth.value.split(' ');
      this.thisYearOrders = await this.fetchRecords(month, year);

      const lastYear = parseInt(year) - 1;
      this.lastYearOrders = await this.fetchRecords(month, lastYear.toString());
    }

    this.$nextTick(() => {
      let firstTab = new bootstrap.Tab(document.querySelector('#tabNav .nav-item:first-child .nav-link'));
      firstTab.show();
      this.tabsVisible = true;
    });
  },
};
</script>